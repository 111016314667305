import { Service } from '@wix/ambassador-bookings-services-v2-service/types';

export interface BookButtonViewModel {
  isPendingApprovalFlow?: boolean;
  navigationInitiatedBy?: string;
}
export const bookButtonViewModelFactory = (
  service: Service,
): BookButtonViewModel => {
  return {
    isPendingApprovalFlow: service?.onlineBooking?.requireManualApproval!,
  };
};
