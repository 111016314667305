import { getSiteRolloutStatusForV1Shutdown } from '@wix/ambassador-bookings-v1-site-rollout-status/http';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

export const isEcomSite = async (
  flowAPI: ControllerFlowAPI,
): Promise<boolean> => {
  try {
    const { data: response } = await flowAPI.httpClient.request(
      getSiteRolloutStatusForV1Shutdown({}),
    );
    return Boolean(response.siteRolloutStatus?.isBookingPlatformReady);
  } catch (e) {
    return true;
  }
};
